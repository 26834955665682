export class Student {

    id: number;
    name: string;
    allergy: string;
    pinnum: number;
    sign: boolean;
    address: string;
    emergency: string;
    pickup: string;
    parentEmail: string;
    phone: string;
    school: number;
    course: number;
    sex: string;
    parentName: string;
    birthday: string;
    noICF: boolean;
    immunizations: string;
    balance: any;
}

export class Parent {
    id: number;
    name: string;
    email: string;
    phone: string;
}