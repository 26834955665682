export class Staff {

    public id: number;
    public sid: number;
    public sname: string;
    public email: string;
    public sex: string;
    public pinnum: string;
    public sign: boolean;
    public school: number;
    public password: string;
    // public editable: boolean = false
    public course: number;
    public class: number;
}