export class Classes {
    id: number;
    school_id: number;
    class_name: string;
    start_date: string;
    end_date: string;
    class_time_begin: string;
    class_time_end: string;
    class_type: string;
    paid_type: string;
    paid_fee: number;
    address: string;
}